























































import Vue from 'vue';

import { mapActions } from 'vuex';

import { required } from 'vuelidate/lib/validators';
import { loginActions } from '@/store/modules/login/names';

export default Vue.extend({
    name: 'LoginAuth',

    validations: {
        form: {
            username: { required },
            password: { required },
        },
    },

    data() {
        return {
            emailRules: [
                (v) => !!v || 'E-mail is required',
                (v) => /.+@.+/.test(v) || 'E-mail must be valid',
            ] as [string | Function, string | Function],

            errors: [] as string[],
            process: false,
            form: {
                username: '',
                password: '',
            },
        };
    },

    computed: {
        nameErrors() {
            const errors: string[] = [];
            if (!this.$v.form?.username?.$dirty) return errors;
            if (!this.$v.form.username.required) {
                errors.push('User name is required!');
            }
            return errors;
        },

        passErrors() {
            const errors: string[] = [];
            if (!this.$v?.form?.password?.$dirty) return errors;
            if (!this.$v.form.password.required) {
                errors.push('Password is required!');
            }
            return errors;
        },
    },

    methods: {
        ...mapActions({
            operatorToken: loginActions.getToken,
        }),

        async submit() {
            this.$v.form.$touch();

            if (!this.$v.form.$error) {
                this.process = true;
                this.errors = [];

                const res = await this.operatorToken({ ...this.form });

                if (res) {
                    this.$router.replace({ name: 'Dashboard' });
                }
            }
        },
    },
});
