


































import Vue from 'vue';

import { mapActions, mapState } from 'vuex';

import { dashboardActions } from '../dashboard/store/names';

import { ComposedState } from '@/store/general.types';

export default Vue.extend({
    name: 'DashboardPage',

    data() {
        return {
            newTrialPeriod: null as null | number,
            newLimitDreams: 0 as number,
        };
    },

    computed: {
        ...mapState<ComposedState>({
            trial: (state: ComposedState) => state.dashboard,
        }),
    },

    watch: {
        newTrialPeriod: {
            deep: true,
            async handler(newVal) {
                await this.$nextTick();
                this.newTrialPeriod = newVal.replaceAll(/\D+/g, '');
            },
        },
    },

    async mounted() {
        await this.getTrialPeriod();
    },

    methods: {
        ...mapActions({
            getTrialPeriod: dashboardActions.getTrialPeriod,
            changeTrialPeriod: dashboardActions.changeTrialPeriod,
        }),

        async changeTrialPeriodApi() {
            if (!this.newTrialPeriod) return;

            const formattedTrial = this.newTrialPeriod * 60 * 60 * 24;

            await this.changeTrialPeriod({
                trial_period: formattedTrial,
                limit_dreams: +this.newLimitDreams,
            });

            await this.getTrialPeriod();

            this.newTrialPeriod = null;
        },
    },
});
